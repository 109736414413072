import { reactive, toRefs, computed } from 'vue'
import { server } from 'boot/meteor'
import { allRoutes } from 'src/router/routes'

const state = reactive({
  showDrawer: false,
  mobileSwipeDisabled: false,
  showFilterOptions: false,
  productFamilies: [],
  similarProducts: [],
  randomizedDiscount: 0,
  desktopWebShopMenusBackgroundColor: null,
  promotionLinesBackgroundColor: null,
  thumbStyle: reactive(null),
  barStyle: reactive(null),
  categories: [],
  webShopMenus: [],
  staticPages: [],
  promotionLines: [],
  featuredProducts: [],
  country: { name: null, language: null },
  locale: null,
  languages: null,
  showMobileSmallCart: false,
  showSmallCart: false,
  maxQuantityPerOrder: 0,
  showPopup: false,
  showPopupIndicatorMobile: true,
  popupImage: null,
  popupUrl: null,
  popupTitle: null,
  popupRefreshTimerInSec: 0,
  popupDelayInSec: null,
  intervalRef: null
})

export function useLayout (isB2B = false) {
  const translate = (path) => {
    try {
      let res = state.language
      path.split('.').forEach(e => { res = res[e] })
      return res
    } catch (e) {
      return path
    }
  }

  const startAppFirstPaint = async (locale) => {
    try {
      const configuration = await server.call('loadAppConfiguration', locale)
      state.language = configuration.translations
      state.country = configuration.country
      state.languages = configuration.languages
      state.webShopMenus = configuration.webShopMenus
      state.promotionLines = configuration.promotionLines
      state.randomizedDiscount = 0 /* Math.floor(Math.random() * state.promotionLines.length) */
      state.maxQuantityPerOrder = configuration.maxQuantityPerOrder
      state.desktopWebShopMenusBackgroundColor = configuration.desktopWebShopMenusBackgroundColor
      state.promotionLinesBackgroundColor = configuration.promotionLinesBackgroundColor
      state.locale = locale

      if (isProduction) {
        window.dataLayer.push({
          event: 'general.initialize',
          general: {
            domain: 'domain_' + locale,
            language: state.country.language,
            country: state.country.name,
            currencyCode: state.country.currency
          }
        })
        window.dataLayer.push({
          event: 'general.ready',
          general: {
            device: 'desktop',
            path: '/',
            canonicalPath: '/',
            pageType: 'category'
          }
        })
      }
    } catch (e) {
      console.log('APP START FAILED W ' + locale + '  ', e)
    }
  }

  const startAppRest = async () => {
    try {
      const configurationRest = await server.call('loadAppConfigurationRest', { locale: state.locale, isB2B: isB2B })
      state.productFamilies = configurationRest.productFamilies
      state.categories = configurationRest.categories
      state.staticPages = configurationRest.staticPages
      state.popupRefreshTimerInSec = configurationRest.popupRefreshTimerInSec
      state.popupDelayInSec = configurationRest.popupDelayInSec
      if (configurationRest.popupImage) {
        state.popupImage = configurationRest.popupImage.name
      }
      if (configurationRest.popupUrl) {
        state.popupUrl = configurationRest.popupUrl.name
      }
      if (configurationRest.popupTitle) {
        state.popupTitle = configurationRest.popupTitle.name
      }
    } catch (e) {
      console.log(e)
    }
  }

  const headerHeight = computed(() => {
    if (window.innerWidth > 1040) {
      return 199
    }
    return 100
  })

  const isBrowser = typeof window !== 'undefined'
  const isProduction = process.env.NODE_ENV === 'production' && isBrowser
  // const isProduction = true

  return {
    ...toRefs(state),
    isBrowser,
    startAppRest,
    startAppFirstPaint,
    translate,
    headerHeight,
    isProduction
  }
}

function getRouteFromLanguages (lang, name) {
  const pathTree = allRoutes.find(route => route.meta.locale.includes(lang))
  const foundPath = pathTree.children.find(path => path.name === name)
  return foundPath ? foundPath.path : pathTree.children.find(path => path.name === 'Profil').children.find(profilChild => profilChild.name === name).path
}

function getValidUrlByLang (lang, name, url, slug, productCode) {
  let routeByLang = ''
  if ((url && url.length > 0) || slug) {
    routeByLang = getRouteFromLanguages(lang, name)
    if (url) routeByLang = routeByLang.replace(':url', url.find(u => u.country === lang).name)
    if (slug) routeByLang = routeByLang.replace(':slug', slug.find(b => b.country === lang).name)
    if (productCode) routeByLang = routeByLang.replace(':productCode', productCode)
  }
  return routeByLang
}

export async function buildUpAlternateTags ({
  name,
  url,
  slug,
  productCode
}) {
  const loadLanguages = async () => {
    const res = await server.call('loadLanguages')
    state.languages = res.languages
  }
  const defaultRouteByLang = getValidUrlByLang('hu', name, url, slug, productCode)
  const alternateTags = {
    default: {
      rel: 'alternate',
      href: 'https://puregoldprotein.com/' + defaultRouteByLang,
      hreflang: 'x-default'
    }
  }
  if (!state.languages) {
    await loadLanguages()
  }
  state.languages.forEach(e => {
    if (e.name !== 'en' && e.name !== 'de') {
      const routeByLang = getValidUrlByLang(e.name, name, url, slug, productCode)
      alternateTags[e.name] = {
        rel: 'alternate',
        href: `https://puregoldprotein.${e.name}/${routeByLang}`,
        hreflang: e.name !== 'at' ? e.name : 'de-at'
      }
    }
  })
  return alternateTags
}
