import { reactive, toRefs, computed } from 'vue'
import { server } from 'boot/meteor'
import { useLayout } from 'src/composables/useLayout'
import { Notify, useQuasar } from 'quasar'
const { translate, locale } = useLayout()

const originalState = {
  user: null,
  isLoggedIn: false,
  showLogin: false,
  showRegister: false,
  showForgottenPassword: false,
  lang: null,
  showNoPasswordSet: false,
  userDetailsLoaded: false,
  authTab: 'login',
  wishLists: null,
  wishListPagination: {
    sortBy: 'createdAt',
    descending: true,
    limit: 10,
    skip: 0,
    rowsNumber: 0
  }
}

const state = reactive(originalState)

const logout = async () => {
  if (process.env.SERVER) { return }
  localStorage.removeItem('auth_token')
  state.userDetailsLoaded = false
  state.isLoggedIn = false
  state.user = null
  await server.logout()
  Notify.create({ type: 'positive', message: translate('profile.loggedOut') })
}

export function useAuth () {
  const $q = useQuasar()

  async function loadUser () {
    try {
      state.user = await server.call('loadUserProfile', locale.value)
      state.userDetailsLoaded = true
      if (state.user && (state.user.banned || state.user.removed)) {
        await logout()
      }
    } catch (e) {
      console.log('LOAD USER ERROR', e)
      $q.notify({ type: 'negative', message: e.reason })
    }
  }

  async function updateUser () {
    try {
      await server.call('updateUser', state.user)
      Notify.create({ timeout: 2500, type: 'positive', message: translate('profile.saved') })
    } catch (e) {
      console.log(e)
      Notify.create({ type: 'negative', message: e.reason })
    }
  }

  const authUserWithEmailAndPassword = async ({ email, password, b2blogin = false }) => {
    try {
      const res = await server.login({ password, user: { email } })
      state.isLoggedIn = !!res
      localStorage.setItem('auth_token', server.token)
      await loadUser()
      state.showLogin = false
      if (b2blogin !== true) {
        redirectAfterLogin()
      }
    } catch (e) {
      console.log('ERROR', e)
      if (e.reason.reason === 'User has no password set') {
        state.showNoPasswordSet = true
      } else if (e.reason.reason === 'Incorrect password') {
        Notify.create({ type: 'info', message: translate(`error.${e.reason.reason}`) })
      } else if (e.reason === 'EMAIL_NOT_VERIFIED') {
        Notify.create({ type: 'info', message: translate('error.EMAIL_NOT_VERIFIED') })
      } else if (e.reason && e.reason.reason === 'User not found') {
        Notify.create({ type: 'info', message: translate('error.User not found') })
      } else {
        Notify.create({ type: 'info', message: e.reason })
      }
    }
  }

  function redirectAfterLogin () {
    Notify.create({ type: 'positive', message: translate('auth.successfulLogin') })
    state.showLogin = false
  }

  const authUserFromToken = async () => {
    const authToken = localStorage.getItem('auth_token')
    state.isLoggedIn = !!authToken
    if (authToken) {
      try {
        const res = await server.login({ resume: authToken })
        state.isLoggedIn = !!res
      } catch (e) {
        if (e.error === '403') {
          await logout()
        }
      }
      await loadUser()
    }
  }

  return {
    ...toRefs(state),
    logout,
    loadUser,
    updateUser,
    authUserFromToken,
    authUserWithEmailAndPassword
  }
}
