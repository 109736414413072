import { computed, reactive, toRefs, watch } from 'vue'
import { debounce, Notify } from 'quasar'
import { useLayout } from 'src/composables/useLayout'
import { server } from 'boot/meteor'
import { useAuth } from 'src/composables/useAuth'

const { locale, translate, country, maxQuantityPerOrder, isProduction } = useLayout()
const { userDetailsLoaded, user } = useAuth()
const isPartner = computed(() => {
  return user.value && user.value.isPartner
})
let tempOrigNetPrice

const state = reactive({
  fullShoppingCart: [],
  newCartItem: null,
  showCartPopup: false,
  cartGift: null,
  gifts: null,
  showCartgiftOption: false,
  couponOrVoucherCode: null,
  couponOrVoucherLoaded: false,
  couponOrVoucherErrorMessage: null,
  coupon: null,
  voucher: null,
  validatedReferralGiftCoupon: null,
  referralCodeUsed: false,
  delivery: {
    type: null,
    foxPostLocationId: null,
    prices: {
      gross_price: null
    }
  },
  pickUpPoints: [],
  deliveryOptions: [],
  foxPostLocations: [],
  paymentOptions: [],
  payment: {
    value: null,
    prices: {
      gross_price: null
    }
  },
  selectedPickUpPoint: null,
  showChooseGift: false,
  customerDetails: {
    differentDeliveryAddress: false,
    newUser: false,
    name: null,
    firstName: null,
    lastName: null,
    gender: null,
    phone: null,
    email: null,
    zip: null,
    district: null,
    city: null,
    street: null,
    houseNumber: null,
    country_code: 'hu',
    taxnumber: null,
    postal_name: null,
    postal_zip: null,
    postal_district: null,
    postal_street: null,
    postal_country_code: 'hu',
    company: null,
    customer_comment: null,
    termsAccepted: false,
    signUpNewsLetter: false,
    password: null,
    corporalPurchase: false
  },
  purchaseError: null,
  showTermsAcceptedError: false,
  cartLeaveListenerSet: false,
  isCheckout: false
})

const calcCartItemWithStackDiscount = ({ cartItem, priceWithoutCouponGross }) => {
  let retVal
  if (cartItem.type === 'PRODUCT' && cartItem.product.stackDiscount) {
    retVal = priceWithoutCouponGross * (1 - (getStackDiscount({ stackDiscount: cartItem.product.stackDiscount, quantity: cartItem.quantity }) / 100))
  } else if (cartItem.type === 'MAIN_PRODUCT' && cartItem.mainProduct && cartItem.mainProduct.stackDiscount) {
    retVal = priceWithoutCouponGross * (1 - (getStackDiscount({ stackDiscount: cartItem.mainProduct.stackDiscount, quantity: cartItem.quantity }) / 100))
  }
  return retVal
}

const getStackDiscount = ({ stackDiscount, quantity }) => {
  let retVal = stackDiscount[quantity - 1]
  if (retVal === undefined) {
    retVal = stackDiscount[stackDiscount.length - 1]
  }
  return retVal
}

let updateShoppingCart = () => {
  if (userDetailsLoaded.value) {
    try {
      server.call('updateShoppingCart', state.fullShoppingCart)
    } catch (e) {
      console.log(e)
    }
  }
}

const isProductInCart = ({ productCode, mainProductId, type }) => {
  let foundItem = false
  for (let i = 0; i < state.fullShoppingCart.length && !foundItem; i++) {
    const cartItem = state.fullShoppingCart[i]
    if (
      (type === 'PRODUCT' && cartItem.product && cartItem.product.product_code === productCode) ||
      (type === 'MAIN_PRODUCT' && cartItem.mainProduct && cartItem.mainProduct._id === mainProductId && cartItem.product.product_code === productCode)
    ) {
      foundItem = true
    }
  }
  return foundItem
}

const loadCouponOrVoucher = async () => {
  try {
    const res = await server.call('findCoupon', { code: state.couponOrVoucherCode, locale: locale.value, shoppingCart: state.fullShoppingCart })
    if (res && res.coupon) {
      state.couponOrVoucherErrorMessage = res.errorMessage
      const couponProduct = res.coupon.product
      if (couponProduct && !isProductInCart({ productCode: couponProduct.product_code, mainProductId: couponProduct.mainProduct ? couponProduct.mainProduct._id : null, type: couponProduct.mainProduct ? 'MAIN_PRODUCT' : 'PRODUCT' })) {
        const couponMainProduct = couponProduct.mainProduct
        if (couponMainProduct) {
          await useCart().addProduct({ quantity: 1, type: 'MAIN_PRODUCT', product: couponProduct, gifts: null, mainProduct: couponMainProduct })
        } else {
          await useCart().addProduct({ quantity: 1, type: 'PRODUCT', product: couponProduct, gifts: [] })
        }
      }
      state.coupon = res.coupon
    } else {
      const res = await server.call('findVoucher', { code: state.couponOrVoucherCode })
      if (res && res.voucher) {
        state.couponOrVoucherErrorMessage = res.errorMessage
        state.voucher = res.voucher
      } else if (res.errorMessage === 'VOUCHER_NOT_FOUND') {
        const res = await server.call('doGetGiftFromReferral', { codeFromWeb: state.couponOrVoucherCode, locale: locale.value, userId: user.value ? user.value._id : null, needSave: false })
        if (res) {
          state.couponOrVoucherErrorMessage = res.errorMessage
          if (res.validatedReferralGift) {
            if (res.validatedReferralGift.type === 'Product') {
              useCart().removeProduct({ type: 'REFERRAL_GIFT', product: res.validatedReferralGift.product })
              useCart().addProduct({ quantity: 1, type: 'REFERRAL_GIFT', product: res.validatedReferralGift.product, gifts: [], mainProduct: {}, refGiftCode: state.couponOrVoucherCode })
            } else {
              state.validatedReferralGiftCoupon = res.validatedReferralGift
            }
          } else if (res.isSuccess) {
            state.referralCodeUsed = true
          }
        }
      }
    }
    state.couponOrVoucherLoaded = true
  } catch (e) {
    Notify.create({ type: 'negative', message: e.reason })
  }
}

watch(state.fullShoppingCart, async () => {
  if ((state.coupon || this.state.voucher) && state.couponOrVoucherCode) {
    await loadCouponOrVoucher()
  }
}, { deep: true })

updateShoppingCart = debounce(updateShoppingCart, 5000)

watch(() => state.fullShoppingCart, val => {
  updateShoppingCart()
}, { deep: true })

const checkIfCouponProductRemoved = ({ productCode }) => {
  let retVal = false
  if (state.coupon && state.coupon.product && (productCode === state.coupon.product.product_code || (state.coupon.product.mainProduct && productCode === state.coupon.product.mainProduct._id))) {
    retVal = true
  }
  return retVal
}

const updateGTMGifts = ({ oldGifts, newGifts }) => {
  if (isProduction) {
    if (oldGifts && oldGifts.length > 0) {
      window.dataLayer.push({ ecommerce: null })
      window.dataLayer.push({
        event: 'removeFromCart',
        ecommerce: {
          currencyCode: country.value.currency,
          remove: {
            products: oldGifts.map(gift => {
              return {
                name: gift.product.productName,
                id: gift.product.product_code,
                price: gift.prices.gross_price,
                category: gift.product.categories && gift.product.categories[0] ? gift.product.categories[0].name : '',
                variant: gift.product.taste,
                quantity: gift.quantity
              }
            })
          }
        }
      })
    }
    if (newGifts && newGifts.length > 0) {
      window.dataLayer.push({ ecommerce: null })
      window.dataLayer.push({
        event: 'addToCart',
        ecommerce: {
          currencyCode: country.value.currency,
          add: {
            products: newGifts.map(gift => {
              return {
                name: gift.product.productName,
                id: gift.product.product_code,
                price: gift.prices.gross_price,
                category: gift.product.categories && gift.product.categories[0] ? gift.product.categories[0].name : '',
                variant: gift.product.taste,
                quantity: gift.quantity
              }
            })
          }
        }
      })
    }
  }
}

export function useCart (isBio) {
  const resetShoppingCart = () => {
    state.fullShoppingCart = []
    state.couponOrVoucherCode = null
    state.couponOrVoucherLoaded = false
    state.termsAccepted = false
    state.coupon = null
    state.voucher = null
    state.validatedReferralGiftCoupon = null
    state.referralCodeUsed = false
    updateLocalStorageWithShoppingCart()
  }

  const updateLocalStorageWithShoppingCart = () => {
    if (process.env.SERVER) { return }
    localStorage.setItem('shoppingCart', JSON.stringify(computedFullShoppingCart.value.map(cartItem => cartItemToBasicCartItem(cartItem))))
  }

  const cartItemToBasicCartItem = (cartItem) => {
    switch (cartItem.type) {
      case 'REFERRAL_GIFT' : {
        return {
          type: cartItem.type,
          quantity: cartItem.quantity,
          priceWithoutCouponGross: cartItem.product.prices.gross_price,
          showDiscountedPrice: cartItem.showDiscountedPrice ?? false,
          refGiftCode: cartItem.refGiftCode,
          product: {
            _id: cartItem.product._id,
            product_code: cartItem.product.product_code,
            productName: cartItem.product.productName,
            prices: cartItem.product.prices,
            img: cartItem.product.img,
            packaging: cartItem.product.packaging ?? '',
            taste: cartItem.product.taste ?? '',
            vatPercentages: cartItem.product.vatPercentages
          },
          gifts: []
        }
      }
      case 'GIFT' : return {
        type: cartItem.type,
        quantity: cartItem.quantity,
        priceWithoutCouponGross: cartItem.priceWithoutCouponGross,
        showDiscountedPrice: cartItem.showDiscountedPrice,
        gift: {
          product: {
            _id: cartItem.gift.product._id,
            product_code: cartItem.gift.product.product_code,
            prices: cartItem.gift.product.prices,
            img: cartItem.gift.product.img,
            productName: cartItem.gift.product.productName,
            packaging: cartItem.gift.product.packaging,
            taste: cartItem.gift.product.taste
          },
          prices: cartItem.gift.prices
        }
      }
      case 'CART_GIFT' :
        return {
          type: cartItem.type,
          quantity: cartItem.quantity,
          priceWithoutCouponGross: cartItem.priceWithoutCouponGross,
          showDiscountedPrice: true,
          payment: cartItem.product.payment,
          product: {
            _id: cartItem.product.identifier,
            product_code: cartItem.product.product_code,
            productName: cartItem.product.productName,
            prices: cartItem.product.prices,
            discount: cartItem.product.discount,
            hasDiscount: cartItem.product.hasDiscount,
            img: cartItem.product.img,
            packaging: cartItem.product.packaging,
            taste: cartItem.product.taste
          },
          gifts: []
        }
      case 'PRODUCT': {
        const res = {
          type: cartItem.type,
          quantity: cartItem.quantity,
          priceWithoutCouponGross: cartItem.priceWithoutCouponGross,
          showDiscountedPrice: cartItem.showDiscountedPrice ?? false,
          product: {
            _id: cartItem.product._id,
            product_code: cartItem.product.product_code,
            productName: cartItem.product.productName,
            prices: cartItem.product.prices,
            discount: cartItem.product.discount,
            stackDiscount: cartItem.product.stackDiscount,
            badges: cartItem.product.badges,
            hasBadges: cartItem.product.hasBadges,
            hasDiscount: cartItem.product.hasDiscount,
            img: cartItem.product.img,
            packaging: cartItem.product.packaging ?? '',
            taste: cartItem.product.taste ?? ''
          },
          gifts: []
        }
        if (cartItem.gifts && cartItem.gifts.length > 0) {
          cartItem.gifts.forEach(gift => {
            res.gifts.push({
              _id: gift._id,
              quantity: gift.quantity,
              prices: gift.prices,
              product: {
                product_code: gift.product.product_code,
                prices: gift.product.prices,
                img: gift.product.img,
                productName: gift.product.productName,
                packaging: gift.product.packaging,
                taste: gift.product.taste
              }
            }
            )
          })
        }
        return res
      }

      case 'MAIN_PRODUCT': {
        const res = {
          type: cartItem.type,
          quantity: cartItem.quantity,
          priceWithoutCouponGross: cartItem.priceWithoutCouponGross,
          showDiscountedPrice: cartItem.showDiscountedPrice,
          mainProduct: {
            _id: cartItem.mainProduct._id,
            name: cartItem.mainProduct.name,
            img: cartItem.mainProduct.img,
            discount: cartItem.mainProduct.discount,
            hasDiscount: cartItem.mainProduct.hasDiscount,
            stackDiscount: cartItem.mainProduct.stackDiscount
          },
          product: {
            product_code: cartItem.product.product_code,
            prices: cartItem.product.prices,
            badges: cartItem.product.badges,
            hasBadges: cartItem.product.hasBadges,
            discount: cartItem.product.discount,
            hasDiscount: cartItem.product.hasDiscount,
            stackDiscount: cartItem.product.stackDiscount,
            img: cartItem.product.img,
            productName: cartItem.product.productName,
            taste: cartItem.product.taste,
            packaging: cartItem.product.packaging,
            _id: cartItem.product._id
          },
          gifts: []
        }
        if (cartItem.gifts && cartItem.gifts.length > 0) {
          cartItem.gifts.forEach(gift => {
            res.gifts.push({
              quantity: gift.quantity,
              prices: gift.prices,
              product: {
                _id: gift.product._id,
                product_code: gift.product.product_code,
                prices: gift.product.prices,
                img: gift.product.img,
                productName: gift.product.productName,
                packaging: gift.product.packaging,
                taste: gift.product.taste
              },
              gifts: []
            })
          })
        }
        return res
      }
      case 'GROUP_OFFER': {
        const res = {
          ...cartItem,
          groupOffer: {
            ...cartItem.groupOffer,
            items: cartItem.groupOffer.items.map(item => {
              if (item.type === 'PRODUCT') {
                return {
                  ...item,
                  product: {
                    _id: item.product._id,
                    product_code: item.product.product_code,
                    productName: item.product.productName,
                    prices: item.product.prices,
                    discount: item.product.discount,
                    hasDiscount: item.product.hasDiscount,
                    img: item.product.img,
                    packaging: item.product.packaging,
                    taste: item.product.taste
                  }
                }
              } else {
                return {
                  ...item,
                  mainProduct: {
                    _id: item.mainProduct._id,
                    name: item.mainProduct.name,
                    img: item.mainProduct.img,
                    product: {
                      product_code: item.mainProduct.product.product_code,
                      prices: item.mainProduct.product.prices,
                      img: item.mainProduct.product.img,
                      productName: item.mainProduct.product.productName,
                      taste: item.mainProduct.product.taste,
                      packaging: item.mainProduct.product.packaging,
                      _id: item.mainProduct.product._id
                    }
                  },
                  gifts: []
                }
              }
            })
          }
        }
        return res
      }
    }
    return cartItem
  }

  const updateCartItemProduct = ({ oldProduct, mainProduct, product }) => {
    state.fullShoppingCart = computedFullShoppingCart.value.map(fullCartItem => {
      if ((fullCartItem.mainProduct && fullCartItem.mainProduct._id === mainProduct._id) && (fullCartItem.product && fullCartItem.product.product_code === oldProduct.product_code)) {
        fullCartItem.product = { ...product, gifts: product.gifts.map(gift => { return { ...gift, quantity: 0 } }) }
        fullCartItem.gifts = []
        if (isProduction.value) {
          window.dataLayer.push({ ecommerce: null })
          window.dataLayer.push({ event: 'removeFromCart', ecommerce: { currencyCode: country.value.currency, remove: { products: fullCartItemToGtmItems(fullCartItem) } } })
          window.dataLayer.push({ ecommerce: null })
          window.dataLayer.push({ event: 'addToCart', ecommerce: { currencyCode: country.value.currency, add: { products: fullCartItemToGtmItems(fullCartItem) } } })
        }
        return fullCartItem
      }
      return fullCartItem
    })
    updateLocalStorageWithShoppingCart()
  }

  const updateGroupOfferItemProduct = ({ groupOfferId, itemId, product }) => {
    state.fullShoppingCart = state.fullShoppingCart.map(cartItem => {
      if (cartItem.type === 'GROUP_OFFER' && cartItem.groupOffer && cartItem.groupOffer._id === groupOfferId) {
        cartItem.groupOffer.items = cartItem.groupOffer.items.map(item => {
          if (item.type === 'MAIN_PRODUCT' && item._id === itemId) {
            item.mainProduct.product = product
          }
          return item
        })
      }
      return cartItem
    })
    updateLocalStorageWithShoppingCart()
  }

  const fullCartItemToGtmItems = (fullCartItem) => {
    if (fullCartItem.type !== 'GROUP_OFFER') {
      const res = [{
        name: fullCartItem.product.productName,
        id: fullCartItem.product.product_code,
        price: fullCartItem.priceWithoutCouponGross,
        category: fullCartItem.product.categories && fullCartItem.product.categories[0] ? fullCartItem.product.categories[0].name : '',
        variant: fullCartItem.product.taste,
        quantity: fullCartItem.quantity
      }]
      if (fullCartItem.gifts && fullCartItem.gifts.length > 0) {
        fullCartItem.gifts.forEach(gift => {
          res.push({
            name: gift.product.productName,
            id: gift.product.product_code,
            price: gift.prices.gross_price,
            category: gift.product.categories && gift.product.categories[0] ? gift.product.categories[0].name : '',
            variant: gift.product.taste,
            quantity: gift.quantity
          })
        })
      }
      return res
    } else if (fullCartItem.type === 'GROUP_OFFER') {
      const res = []
      fullCartItem.groupOffer.items.forEach(gItem => {
        if (gItem.mainProduct) {
          const product = gItem.mainProduct.product
          res.push({
            name: product.productName,
            quantity: fullCartItem.quantity,
            id: product.product_code,
            price: product.prices.gross_price,
            category: product.categories && product.categories[0].name ? product.categories[0].name : '',
            variant: product.taste
          })
        } else {
          const product = gItem.product
          res.push({
            name: product.productName,
            quantity: fullCartItem.quantity,
            id: product.product_code,
            price: product.prices.gross_price,
            category: product.categories && product.categories[0].name ? product.categories[0].name : '',
            variant: product.taste
          })
        }
      })
      return res
    } else {
      const res = []
      if (fullCartItem.items && fullCartItem.items.length > 0) {
        fullCartItem.items.forEach(item => {
          if (item.type === 'MAIN_PRODUCT') {
            res.push({
              name: item.mainProduct.product.productName,
              id: item.mainProduct.product.product_code,
              price: item.prices.gross_price,
              category: item.mainProduct.product.categories && item.mainProduct.product.categories[0] ? item.mainProduct.product.categories[0].name : '',
              variant: item.mainProduct.product.taste,
              quantity: item.quantity
            })
          } else {
            res.push({
              name: item.product.productName,
              id: item.product.product_code,
              price: item.prices.gross_price,
              category: item.product.categories && item.product.categories[0] ? item.product.categories[0].name : '',
              variant: item.product.taste,
              quantity: item.quantity
            })
          }
        })
      }
      return res
    }
  }
  const updateCartItemQuantity = ({ quantity, type, product, mainProduct, groupOffer }) => {
    state.fullShoppingCart = computedFullShoppingCart.value.map(fullCartItem => {
      if (
        (type === 'PRODUCT' && fullCartItem.product.product_code === product) ||
        (type === 'MAIN_PRODUCT' && fullCartItem.mainProduct && fullCartItem.mainProduct._id === mainProduct && fullCartItem.product.product_code === product) ||
        (type === 'GROUP_OFFER' && groupOffer && fullCartItem.groupOffer && fullCartItem.groupOffer._id === groupOffer._id)
      ) {
        let cartItemMaxQuantity = maxQuantityPerOrder.value
        if (fullCartItem.product && fullCartItem.product.hasDiscount && fullCartItem.product.discount.maxQuantityPerOrder) {
          cartItemMaxQuantity = fullCartItem.product.discount.maxQuantityPerOrder
        } else if (fullCartItem.type !== 'GROUP_OFFER' && fullCartItem.product.maxQuantityPerOrder) {
          cartItemMaxQuantity = fullCartItem.product.maxQuantityPerOrder
        } else if (fullCartItem.type === 'GROUP_OFFER') {
          cartItemMaxQuantity = fullCartItem.groupOffer.maxQuantityPerOrder
        }
        let foundError = false
        if (quantity > fullCartItem.quantity) {
          if (quantity > cartItemMaxQuantity && !isPartner.value) {
            Notify.create({ type: 'info', message: translate('product.maxQuantityPerOrderReached') })
            foundError = true
          }
          if (type !== 'GROUP_OFFER' && quantity > fullCartItem.product.stock) {
            Notify.create({ type: 'info', message: translate('products.notEnoughInStock') })
            foundError = true
          } else if (type === 'GROUP_OFFER' && fullCartItem.groupOffer.items.some(item => { return item.type === 'MAIN_PRODUCT' ? item.mainProduct.product.stock < quantity : item.product.stock < quantity })) {
            Notify.create({ type: 'info', message: translate('products.notEnoughInStock') })
            foundError = true
          }
        }
        if (!foundError) {
          if (!isProduction) {
            if (quantity < fullCartItem.quantity) { // DECREMENTED
              window.dataLayer.push({ ecommerce: null })
              window.dataLayer.push({
                event: 'removeFromCart',
                ecommerce: {
                  currencyCode: country.value.currency,
                  remove: { products: fullCartItemToGtmItems({ ...fullCartItem, quantity: fullCartItem.quantity - quantity, gifts: [] }) } // NO GIFTS SINCE ONLY THE ACTUAL PRODUCT QUANTITY UPDATES
                }
              })
            } else if (quantity > fullCartItem.quantity) { // INCREMENTED
              window.dataLayer.push({ ecommerce: null })
              window.dataLayer.push({
                event: 'addToCart',
                ecommerce: {
                  currencyCode: country.value.currency,
                  add: { products: fullCartItemToGtmItems({ ...fullCartItem, quantity: quantity - fullCartItem.quantity, gifts: [] }) } // NO GIFTS SINCE ONLY THE ACTUAL PRODUCT QUANTITY UPDATES
                }
              })
            }
          }
          fullCartItem.quantity = quantity
          return fullCartItem
        }
      }
      return fullCartItem
    })
    updateLocalStorageWithShoppingCart()
  }

  const removeGiftFromCartItems = ({ productCode }) => {
    state.fullShoppingCart = computedFullShoppingCart.value.map(fullCartItem => {
      if (fullCartItem.gifts && fullCartItem.gifts.length > 0) {
        const oldGifts = fullCartItem.gifts
        fullCartItem.gifts = fullCartItem.gifts.filter(gift => !(gift.product.product_code === productCode))
        updateGTMGifts({ oldGifts: oldGifts, newGifts: fullCartItem.gifts })
      }
      return fullCartItem
    })
    updateLocalStorageWithShoppingCart()
    refreshFullCartItems()
  }

  const updateCartItemGifts = ({ gifts, type, product, mainProduct }) => {
    if (gifts && gifts.length > 0) {
      gifts = gifts.filter(e => e.quantity > 0)
    }
    state.fullShoppingCart = computedFullShoppingCart.value.map(fullCartItem => {
      if (
        (type === 'PRODUCT' && fullCartItem.product.product_code === product) ||
        (type === 'MAIN_PRODUCT' && fullCartItem.mainProduct && fullCartItem.mainProduct._id === mainProduct && fullCartItem.product.product_code === product)
      ) {
        updateGTMGifts({ oldGifts: fullCartItem.gifts, newGifts: gifts })
        fullCartItem.gifts = gifts
        return fullCartItem
      }
      return fullCartItem
    })
    updateLocalStorageWithShoppingCart()
  }
  const addProduct = (payload) => {
    const { quantity, type, mainProduct, product, gifts, groupOffer } = payload
    try {
      let foundItem = false
      const products = fullCartItemToGtmItems(payload)
      state.fullShoppingCart = computedFullShoppingCart.value.filter(e => e.quantity > 0).map(cartItem => {
        if (
          (type === 'PRODUCT' && cartItem.product && cartItem.product.product_code === product.product_code) ||
          (type === 'MAIN_PRODUCT' && cartItem.mainProduct && cartItem.mainProduct._id === mainProduct._id && cartItem.product.product_code === product.product_code)
        ) {
          foundItem = true
          cartItem.quantity += quantity
          // IDE KÉNE EGY RECALC VALSZEG
          if (isProduction) {
            window.dataLayer.push({ ecommerce: null })
            window.dataLayer.push({
              event: 'addToCart',
              ecommerce: {
                currencyCode: country.value.currency,
                add: { products }
              }
            })
          }
          if (gifts && gifts.length > 0) {
            if (cartItem.gifts && cartItem.gifts.length > 0) {
              gifts.forEach(selectedGift => {
                const selectedCartGift = cartItem.gifts.find(gift => selectedGift.product.product_code === gift.product.product_code)
                if (selectedCartGift) {
                  selectedCartGift.quantity += selectedGift.quantity
                } else {
                  cartItem.gifts.push(selectedGift)
                }
              })
            } else {
              cartItem.gifts = gifts
            }
          }
        } else if (type === 'GROUP_OFFER' && cartItem.groupOffer && cartItem.groupOffer._id === groupOffer._id) {
          let tasteCount = 0
          let mainProductCount = 0
          for (const [i, item] of groupOffer.items.entries()) {
            if (item.mainProduct && cartItem.groupOffer.items[i].mainProduct && item.mainProduct.product.taste === cartItem.groupOffer.items[i].mainProduct.product.taste) {
              tasteCount++
            }
          }
          groupOffer.items.forEach(item => {
            if (item.mainProduct) {
              mainProductCount++
            }
          })

          if (tasteCount === mainProductCount) {
            cartItem.quantity += quantity
            foundItem = true
          }
        }
        return cartItem
      })
      if (!foundItem) {
        state.fullShoppingCart = [payload, ...state.fullShoppingCart]
        if (isProduction) {
          window.dataLayer.push({ ecommerce: null })
          window.dataLayer.push({
            event: 'addToCart',
            ecommerce: {
              currencyCode: country.value.currency,
              add: { products }
            }
          })
        }
      }
      updateLocalStorageWithShoppingCart()
    } catch (e) {
      console.log(e)
    }
  }
  const removeProduct = (payload) => {
    const { product, type, mainProduct, groupOffer, allCartGifts } = payload
    state.fullShoppingCart = computedFullShoppingCart.value.filter((fullCartItem) => {
      return !(
        (['PRODUCT', 'REFERRAL_GIFT'].includes(type) && fullCartItem.product && fullCartItem.product.product_code === product.product_code) ||
        (type === 'MAIN_PRODUCT' && fullCartItem.mainProduct && fullCartItem.mainProduct._id === mainProduct._id && fullCartItem.product.product_code === product.product_code) ||
        (type === 'GROUP_OFFER' && fullCartItem.groupOffer && fullCartItem.groupOffer._id === groupOffer._id && fullCartItem.groupOffer.items === groupOffer.items) ||
        (type === 'CART_GIFT' && fullCartItem.type === type && allCartGifts) ||
        (type === 'CART_GIFT' && !allCartGifts && fullCartItem.type === type && fullCartItem.product.identifier === product.identifier)
      )
    })
    if ((product || mainProduct) && checkIfCouponProductRemoved({ productCode: product ? product.product_code : mainProduct._id })) {
      state.couponOrVoucherCode = null
      state.couponOrVoucherLoaded = false
      state.couponOrVoucherErrorMessage = null
      state.coupon = null
    }
    updateLocalStorageWithShoppingCart()
    try {
      if (isProduction) {
        window.dataLayer.push({ ecommerce: null })
        window.dataLayer.push({
          event: 'removeFromCart',
          ecommerce: {
            currencyCode: country.value.currency,
            remove: { products: fullCartItemToGtmItems(payload) }
          }
        })
      }
    } catch (e) {
      console.log('Failed updating GTM', e)
    }
  }

  const shoppingCartCount = computed(() => {
    let res = 0
    state.fullShoppingCart.forEach(cartItem => {
      if (cartItem.type !== 'GROUP_OFFER') {
        res += cartItem.quantity
        if (cartItem.gifts && cartItem.gifts.length > 0) {
          cartItem.gifts.forEach(e => {
            res += e.quantity
          })
        }
      } else {
        res += (cartItem.groupOffer.items.length * cartItem.quantity)
      }
    })
    return res
  })

  const itemsCountWithGifts = computed(() => {
    let res = 0
    state.fullShoppingCart.forEach(cartItem => {
      if (cartItem.type !== 'GROUP_OFFER') {
        res += cartItem.quantity
        if (cartItem.gifts && cartItem.gifts.length > 0) {
          cartItem.gifts.forEach(gift => {
            res += gift.quantity
          })
        }
      } else {
        res += (cartItem.groupOffer.items.length * cartItem.quantity)
      }
    })
    return res
  })

  const itemsCountWithGiftsIgnoreQuantity = computed(() => {
    let res = 0
    state.fullShoppingCart.forEach(cartItem => {
      if (cartItem.type !== 'GROUP_OFFER') {
        res += 1
        if (cartItem.gifts && cartItem.gifts.length > 0) {
          res += cartItem.gifts.length
        }
      } else {
        res += cartItem.groupOffer.items.length
      }
    })
    return res
  })

  const refreshFullCartItems = async () => {
    if (state.fullShoppingCart && state.fullShoppingCart.length > 0) {
      let resellerCategory
      if (isBio) {
        resellerCategory = isPartner.value ? user.value.resellerCategory : null
      }
      try {
        const res = await server.call('validateCartItems', {
          locale: locale.value,
          cartItems: state.fullShoppingCart,
          resellerCategory: resellerCategory,
          payment: null,
          userId: user.value ? user.value._id : null
        })
        state.fullShoppingCart = res.filter(t => t != null)
      } catch (e) {
        console.log(e)
        state.fullShoppingCart = []
        updateLocalStorageWithShoppingCart()
        Notify.create({ type: 'info', message: translate('cart.modifiedCartItems') })
      }
    } else {
      state.fullShoppingCart = []
    }
  }

  const loadDeliveryOptions = async () => {
    if (!state.deliveryOptions || state.deliveryOptions.length === 0) {
      const res = await server.call('getDeliveryOptions', { country: locale.value, isB2B: isBio })
      state.deliveryOptions = res
    }
  }

  const getGiftsPrice = (cartItem) => {
    switch (cartItem.type) {
      case 'PRODUCT': {
        if (cartItem.gifts && cartItem.gifts.length > 0) {
          return cartItem.gifts.reduce((sum, current) => sum + ((current.quantity * current.prices.gross_price) || 0), 0)
        }
        break
      }
      case 'MAIN_PRODUCT': {
        if (cartItem.product && cartItem.gifts && cartItem.gifts.length > 0) {
          return cartItem.gifts.reduce((sum, current) => sum + ((current.quantity * current.prices.gross_price) || 0), 0)
        }
        break
      }
    }
    return 0
  }
  const getGiftsOriginalPrice = (cartItem) => {
    switch (cartItem.type) {
      case 'PRODUCT': {
        if (cartItem.gifts && cartItem.gifts.length > 0) {
          return cartItem.gifts.reduce((sum, current) => sum + ((current.quantity * current.product.prices.gross_price) || 0), 0)
        }
        break
      }
      case 'MAIN_PRODUCT': {
        if (cartItem.product && cartItem.gifts && cartItem.gifts.length > 0) {
          return cartItem.gifts.reduce((sum, current) => sum + ((current.quantity * current.product.prices.gross_price) || 0), 0)
        }
        break
      }
    }
    return 0
  }

  const loadPaymentOptions = async () => {
    if (!state.paymentOptions || state.paymentOptions.length === 0) {
      const res = await server.call('getPaymentOptions', { country: locale.value, isB2B: isBio })
      state.paymentOptions = res
    }
  }

  const onlyCartItemsAmount = computed(() => {
    let res = 0
    if (computedFullShoppingCart.value && computedFullShoppingCart.value.length > 0) {
      computedFullShoppingCart.value.forEach(fullCartItem => {
        if (fullCartItem.type !== 'GROUP_OFFER') {
          if (fullCartItem.type === 'REFERRAL_GIFT') {
            res += fullCartItem.product.prices.gross_price
          } else if (fullCartItem.type === 'CART_GIFT') {
            // res += fullCartItem.product.net_price
          } else {
            res += fullCartItem.priceWithoutCouponGross + getGiftsPrice(fullCartItem)
          }
        } else {
          res += fullCartItem.groupOffer.gross_price * fullCartItem.quantity
        }
      })
    }
    return res
  })

  const giftCartItem = computed({
    get: () => {
      return state.fullShoppingCart.find(e => {
        return e.type === 'GIFT'
      })
    },
    set (val) {
      if (state.fullShoppingCart.some(e => e.type === 'GIFT')) {
        state.fullShoppingCart = computedFullShoppingCart.value.map(e => {
          if (e.type === 'GIFT') {
            e = val
          }
          return e
        })
      } else {
        state.fullShoppingCart.push(val)
      }
      updateLocalStorageWithShoppingCart()
    }
  })

  const amountWithGiftAndCoupon = computed(() => {
    let res = onlyCartItemsAmount.value
    if (giftCartItem.value && giftCartItem.value.gift && giftCartItem.value.gift.prices) {
      res += giftCartItem.value.gift.prices.gross_price
    } if (!state.couponOrVoucherErrorMessage) {
      if (state.coupon) {
        if (state.coupon.discountType === 'FLAT' || state.coupon.discountType === 'PRODUCT') {
          res = res - state.coupon.amount.net_price
        } else if (state.coupon.discountType === 'PERCENTAGE') {
          res = res - (res * (state.coupon.amount.net_price / 100))
        }
      } else if (state.voucher) {
        tempOrigNetPrice = state.voucher.amount.net_price
        res = computeWithVoucher(res)
      } else if (state.validatedReferralGiftCoupon) {
        if (state.validatedReferralGiftCoupon.referralCouponType === 'FLAT' || state.validatedReferralGiftCoupon.referralCouponType === 'PRODUCT') {
          res = res - state.validatedReferralGiftCoupon.amount.net_price
        } else if (state.validatedReferralGiftCoupon.referralCouponType === 'PERCENTAGE') {
          res = res - (res * (state.validatedReferralGiftCoupon.amount.net_price / 100))
        }
        if (res < 0) {
          res = 0
        }
      }
    }
    return res
  })

  const paymentPrice = computed(() => {
    return state.payment.prices.gross_price
  })

  const deliveryPrice = computed(() => {
    return state.delivery.prices.gross_price
  })

  const priceToPay = computed(() => {
    let res = amountWithGiftAndCoupon.value
    const deliveryOption = state.deliveryOptions.find(e => e.type === state.delivery.type)
    if (deliveryOption &&
      (!state.coupon || (state.coupon && !state.coupon.hasFreeDelivery)) &&
      res < country.value.free_delivery_limit_gross && !isBio) {
      res += deliveryPrice.value
    }
    if (state.payment && state.payment.prices && !isBio) {
      res += paymentPrice.value
    }
    if (state.voucher && tempOrigNetPrice > 0) {
      res = computeWithVoucher(res)
    }
    return res
  })

  function computeWithVoucher (res) {
    res = res - tempOrigNetPrice
    if (res < 0) {
      tempOrigNetPrice = res * -1
      res = 0
    } else {
      tempOrigNetPrice = 0
    }
    return res
  }

  const originalAmountWithGift = computed(() => {
    let res = 0
    if (state.fullShoppingCart && state.fullShoppingCart.length > 0) {
      state.fullShoppingCart.forEach(cartItem => {
        switch (cartItem.type) {
          case 'PRODUCT': res += ((cartItem.product.prices.gross_price * cartItem.quantity) + getGiftsOriginalPrice(cartItem)); break
          case 'MAIN_PRODUCT': res += ((cartItem.product.prices.gross_price * cartItem.quantity) + getGiftsOriginalPrice(cartItem)); break
          case 'GROUP_OFFER': res += (cartItem.groupOffer.gross_price_original * cartItem.quantity); break
          case 'CART_GIFT': res += (cartItem.product.prices.gross_price * cartItem.quantity); break
          case 'REFERRAL_GIFT': res += (cartItem.product.prices.gross_price * cartItem.quantity); break
        }
      })
    }
    if (giftCartItem.value && giftCartItem.value.gift && giftCartItem.value.gift.prices) {
      res += giftCartItem.value.gift.prices.gross_price
    }
    return res
  })

  const savedPercentage = computed(() => {
    return originalAmountWithGift.value ? Math.round(((amountWithGiftAndCoupon.value / originalAmountWithGift.value) * 100) - 100) : 0
  })

  const savedTotalAmount = computed(() => {
    let res = originalAmountWithGift.value - amountWithGiftAndCoupon.value
    const deliveryOption = state.deliveryOptions.find(e => e.type === state.delivery.type)
    if (deliveryOption && (amountWithGiftAndCoupon.value >= country.value.free_delivery_limit_gross || (state.coupon && state.coupon.hasFreeDelivery) || (state.voucher && priceToPay.value === 0))) {
      res += deliveryOption.prices.gross_price
    } else if (state.voucher && priceToPay.value < deliveryOption.prices.gross_price) {
      res += deliveryOption.prices.gross_price - priceToPay.value
    }
    return res
  })

  const calculateMpFinalPrice = ({ product, mainProduct, quantity }) => {
    if (product.hasDiscount) {
      if (!mainProduct || !mainProduct.hasDiscount || quantity < mainProduct.discount.freeProductPerQuantity) {
        return (isBio ? product.discount.gross_price : product.discount.prices.gross_price) * quantity
      }
      let nrOfFreeProducts = Math.floor(quantity / mainProduct.discount.freeProductPerQuantity)
      if (nrOfFreeProducts > mainProduct.discount.maxFreeProduct) {
        nrOfFreeProducts = mainProduct.discount.maxFreeProduct
      }
      return (isBio ? product.discount.gross_price : product.discount.prices.gross_price) * (quantity - nrOfFreeProducts)
    }
    if (mainProduct.hasDiscount && quantity >= mainProduct.discount.freeProductPerQuantity) {
      let nrOfFreeProducts = Math.floor(quantity / mainProduct.discount.freeProductPerQuantity)
      if (nrOfFreeProducts > mainProduct.discount.maxFreeProduct) {
        nrOfFreeProducts = mainProduct.discount.maxFreeProduct
      }
      return product.prices.gross_price * (quantity - nrOfFreeProducts)
    }
    return null
  }

  const computedFullShoppingCart = computed(() => {
    let mainProductItemCounts = []
    state.fullShoppingCart.forEach(cartItem => {
      if (cartItem.type === 'MAIN_PRODUCT' && cartItem.mainProduct && cartItem.mainProduct.hasDiscount) {
        if (!mainProductItemCounts.find(e => e._id === cartItem.mainProduct._id && cartItem.product.packaging === e.packaging)) {
          mainProductItemCounts.push({
            _id: cartItem.mainProduct._id,
            quantity: cartItem.quantity,
            packaging: cartItem.product.packaging,
            freeProductPerQuantity: cartItem.mainProduct.discount.freeProductPerQuantity,
            maxFreeProduct: cartItem.mainProduct.discount.maxFreeProduct,
            freeProduct: 0,
            usedFreeProduct: 0
          })
        } else {
          mainProductItemCounts = mainProductItemCounts.map(e => {
            if (e._id === cartItem.mainProduct._id && cartItem.product.packaging === e.packaging) {
              e.quantity += cartItem.quantity
            }
            return e
          })
        }
      }
    })
    mainProductItemCounts = mainProductItemCounts.map(e => {
      const free = Math.floor(e.quantity / e.freeProductPerQuantity)
      return {
        ...e,
        freeProduct: ![null, undefined].includes(free) && free <= e.maxFreeProduct ? free : e.maxFreeProduct
      }
    })
    const res = state.fullShoppingCart.map(cartItem => {
      try {
        if (cartItem.type === 'GIFT') {
          return {
            ...cartItem,
            showDiscountedPrice: false,
            priceWithoutCouponGross: (cartItem.gift.prices.gross_price * cartItem.quantity)
          }
        } else if (cartItem.type === 'GROUP_OFFER') {
          return cartItem
        } else if (cartItem.type === 'CART_GIFT') {
          return cartItem
        } else if (cartItem.type === 'REFERRAL_GIFT') {
          return cartItem
        }
        let showDiscountedPrice = false
        let priceWithoutCouponGross = (cartItem.product.prices.gross_price * cartItem.quantity)
        const mainProductCount = cartItem.type === 'MAIN_PRODUCT' ? mainProductItemCounts.find(e => e._id === cartItem.mainProduct._id && e.packaging === cartItem.product.packaging) : null
        const remainingFreeProducts = mainProductCount ? mainProductCount.freeProduct - mainProductCount.usedFreeProduct : 0
        if ((cartItem.type === 'PRODUCT' || !mainProductCount || remainingFreeProducts <= 0) && cartItem.product.hasDiscount) { // 1+1 only with mainProduct
          priceWithoutCouponGross = (isBio ? cartItem.product.discount.gross_price : cartItem.product.discount.prices.gross_price) * cartItem.quantity
          showDiscountedPrice = true
        }
        if (
          cartItem.type === 'MAIN_PRODUCT' &&
          cartItem.mainProduct &&
          cartItem.mainProduct.hasDiscount &&
          mainProductCount &&
          (mainProductCount.freeProduct - mainProductCount.usedFreeProduct) > 0
        ) {
          let quantityModifier = 0
          if (remainingFreeProducts >= cartItem.quantity) {
            quantityModifier = cartItem.quantity
          } else {
            quantityModifier = remainingFreeProducts
          }
          mainProductCount.usedFreeProduct += quantityModifier
          if (cartItem.product.hasDiscount) {
            priceWithoutCouponGross = (isBio ? cartItem.product.discount.gross_price : cartItem.product.discount.prices.gross_price) * (cartItem.quantity - quantityModifier)
          } else {
            priceWithoutCouponGross = cartItem.product.prices.gross_price * (cartItem.quantity - quantityModifier)
          }
          showDiscountedPrice = true
        }
        if (!isBio) {
          const stackDiscRes = calcCartItemWithStackDiscount({ cartItem: cartItem, priceWithoutCouponGross: priceWithoutCouponGross })
          if (stackDiscRes) {
            priceWithoutCouponGross = stackDiscRes
          }
        }
        return {
          ...cartItem,
          priceWithoutCouponGross,
          showDiscountedPrice
        }
      } catch (e) {
        console.log(e)
        return cartItem
      }
    })

    return res
  })

  const loadFoxPostLocations = async () => {
    state.foxPostLocations = await server.call('getAllCityAndAddressFromFoxSpot')
    state.foxPostLocations.forEach(loc => {
      loc.streets.sort((locA, locB) => locA.street > locB.street ? 1 : -1)
    })
  }

  const clearFoxPostLocations = () => {
    state.foxPostLocations = []
    state.delivery.foxPostLocationId = null
  }

  return {
    ...toRefs(state),
    calculateMpFinalPrice,
    updateCartItemQuantity,
    updateCartItemGifts,
    updateCartItemProduct,
    updateGroupOfferItemProduct,
    addProduct,
    removeProduct,
    shoppingCartCount,
    refreshFullCartItems,
    onlyCartItemsAmount,
    getGiftsPrice,
    priceToPay,
    originalAmountWithGift,
    amountWithGiftAndCoupon,
    savedTotalAmount,
    giftCartItem,
    loadCouponOrVoucher: loadCouponOrVoucher,
    loadPaymentOptions,
    itemsCountWithGifts,
    resetShoppingCart,
    savedPercentage,
    itemsCountWithGiftsIgnoreQuantity,
    loadDeliveryOptions,
    computedFullShoppingCart,
    loadFoxPostLocations,
    clearFoxPostLocations,
    removeGiftFromCartItems
  }
}
